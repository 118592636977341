import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'yesNo',
  standalone: true,
})
export class YesNoPipe implements PipeTransform {
  public constructor(private translate: TranslateService) {}
  public transform(value: string | boolean): string {
    if (this.isEmpty(value)) return null;
    return value ? this.translate.instant('Yes') : this.translate.instant('No');
  }

  public isEmpty(value: string | boolean): boolean {
    return value == null || (typeof value === 'string' && value.trim().length === 0);
  }
}
